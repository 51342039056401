@import "stylesheets/decidim/legacy/variables";

.time-tracker {
  &-activity,
  &-request {
    background-color: white;
    padding: 2rem;
    margin-bottom: 1rem;

    .callout {
      margin: 0;
    }

    .elapsed-time {
      .elapsed-time-clock {
        font-size: 1rem;
      }
    }

    .milestone {
      padding-top: 1em;
      border-top: 1px solid #eee;
    }

    .form__wrapper {
      width: 50%;
    }
  }

  &--clock {
    span:not(:last-child)::after {
      content: " ";
    }

    .text-muted {
      opacity: 0.5;
    }
  }

  &--activity {
    .card--list__item {
      display: flex;
      align-items: center;
      width: 100%;

      .card--list__text {
        width: 70%;
      }

      .card--list__annotation {
        width: 30%;
        text-align: right;
      }
    }
  }

  &--assignee-data {
    margin-bottom: 3rem;
  }

  &--start-button {
    margin-top: 2rem;
    margin-bottom: 1rem;

    &-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.breadcrumb-link {
  color: rgb(var(--secondary-rgb));
}

.comment-link {
  display: flex;
  color: #39747f;

  svg {
    margin-right: 0.4rem;
  }
}

.danger {
  background-color: #cb3c29;
}

.milestones-section {
  padding: 2rem;
  margin-bottom: 1rem;
}

.milestone-card {
  cursor: default !important;
}

.milestone-link {
  display: flex;
  color: #39747f;

  svg {
    margin-top: 0.1rem;
  }

  &:hover {
    cursor: pointer;
  }
}

.secondary {
  background-color: rgb(var(--secondary-rgb));
}

.show-milestones {
  color: $secondary;

  &:hover {
    text-decoration: underline;
  }
}

.success {
  background-color: $success;
}
