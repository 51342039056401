// From Decidicim core
@import "stylesheets/decidim/legacy/variables";
// From npm package
@import "tom-select/dist/scss/tom-select";
@import "hexagon";

.button--double {
  width: fit-content;
}

.notify-announcement {
  background-color: rgba(255, 255, 255, 1);
}

.delete-chapter-button {
  position: absolute;
  font-size: 24px;
  line-height: 30px;
  --tw-text-opacity: 1;
  bottom: 1.5rem;
  left: 9rem;
}

.notify-container {
  padding-top: 2rem;
  background-color: rgba(255, 255, 255, 1);

  #new_note > div > label {
    display: flex;
    flex-direction: column;
    text-align: left;
  }

  .layout-2col.layout-2col__reverse {
    padding: 0rem 1rem;
    aside > div > h5 {
      font-size: 1.2rem;
      margin-bottom: 0.5rem;
    }
  }

  #notify-participants {
    border: 1px solid #cacaca;
    border-radius: 4px;
    background-color: #fafafa;
    margin-bottom: 1rem;
    padding: 0.5rem 0rem;
    text-align: center;
  }

  #notify-note_takers,
  .card-section.notify-chapter-notes {
    border: 1px solid #cacaca;
    border-radius: 4px;
    background-color: #fafafa;
    margin-bottom: 1rem;
    padding: 0.5rem;
  }

  #notify-note_takers {
    text-align: center;
    background-color: rgb(var(--tertiary-rgb) / 0.6);
  }

  .card-section {
    padding: 1rem;
  }

  .section,
  aside,
  .layout-2col__main,
  #notify-chapters {
    background-color: rgba(255, 255, 255, 1);
  }
  .hexagon {
    @include responsive_hexagon();
  }
  .notify-participant {
    display: inline-block;
    margin: 0.5rem;
  }

  .layout-2col.layout-2col__reverse:nth-child(2) > aside {
    display: none;
  }

  .layout-2col__main,
  .layout-2col__aside {
    margin-bottom: 0rem;
    padding-bottom: 0rem;
    margin-top: 0rem;
    padding-top: 0rem;
    min-height: auto;
  }

  .card--notes-editor {
    border-radius: 4px;
    background: #fafafa;
    border: rgb(243 244 247) solid 2px;
    textarea {
      background-color: white;
      border: 1px solid #cacaca;
      border-radius: 4px;
      resize: vertical;
    }
    select {
      background-color: white;
    }
  }
  input,
  textarea {
    &::placeholder {
      color: #333;
    }
  }
  .form-conversations-submit {
    margin-top: 0.8rem;
    justify-content: flex-start;
    align-items: baseline;
    text-align: left;

    .info {
      margin-left: 1rem;
      display: none;
    }
  }
  .form-conversations-reset {
    margin-top: 0.5rem;
  }

  .by-note-taker {
    background-color: rgba(var(--primary-rgb), 0.1);
  }

  .notify-chapter {
    h3 {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin: 1rem 0 1rem 0;
      text-transform: uppercase;
      &::before,
      &::after {
        content: "";
        border-top: 2px solid;
        margin: 0 1rem 0 0;
        flex: 1 0 1rem;
      }
      &:after {
        margin: 0 0 0 1rem;
      }
      &.active {
        color: var(--primary);
      }
      &:empty {
        &::before,
        &::after {
          margin: 0;
        }
      }

      > button {
        font-size: 1rem;
        color: var(--primary);
        display: inline-block;
        margin: 0 -0.5rem 0 0.5rem;
      }
      > .dropdown-pane {
        font-family: "Source Sans Pro", Helvetica, Roboto, Arial, sans-serif;
        margin-left: -3em;
        border: 1px solid #333;
        box-shadow: #999 0px 0px 15px;
        text-align: left;

        .button {
          margin-bottom: 0;
        }
        .delete {
          color: var(--primary);
          position: absolute;
          bottom: 1em;
          right: 1rem;
        }
      }
    }

    .notify-chapter-notes {
      padding: 0.5rem;
      .notify-note {
        padding: 0.5rem;
        border-radius: 4px;
      }

      &:empty,
      &.empty {
        &::before {
          content: attr(data-empty);
          font-style: italic;
          font-size: 0.9rem;
          color: #888;
        }
      }
    }
  }

  .media-object {
    display: flex;

    h4,
    p,
    em {
      font-size: 1rem;
      line-height: 1.1rem;
      margin-bottom: 0.5rem;
      display: block;
    }
    em {
      font-size: 0.8rem;
      color: #999;
    }
    h4 {
      font-weight: 600;
      b {
        font-weight: 700;
      }
    }
    img {
      max-width: 100%;
    }
  }
  .media-object-section {
    .thumbnail {
      width: 70px;
    }
    &.main-section {
      flex: 1;
      margin-left: 1rem;
      padding-top: 1rem;
      p {
        white-space: pre-line;
      }
    }
    .delete {
      margin-bottom: 0.5rem;
    }
  }

  .notify-note {
    .buttons a {
      display: block;
      &.tiny {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
      }
      &.delete {
        svg {
          fill: var(--alert);
        }
      }
      &.edit {
        svg {
          fill: var(--secondary);
        }
      }
    }
  }
}
