/* css for decidim_superspaces */

.participatory-spaces-group {
  border: 1px solid #e8e8e8;
  padding: 1rem;
  margin-bottom: 2rem;
  border-radius: 4px;

  legend {
    font-weight: 600;
    margin-bottom: 1rem;
    padding: 0 0.5rem;
  }

  .check-boxes {
    max-height: 400px;
    overflow-y: auto;
    padding: 0.5rem;
  }

  .check-box {
    margin-bottom: 0.8rem;

    label {
      display: flex;
      align-items: center;
      cursor: pointer;

      input[type="checkbox"] {
        margin: 0 0.5rem 0 0;
      }

      .participatory-space-title {
        line-height: 1.2;
      }
    }

    &:hover {
      .participatory-space-title {
        color: var(--primary);
      }
    }
  }
}
