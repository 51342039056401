// Foundation Variables
$primary-color: null;
$secondary-color: null;
$success-color: null;
$warning-color: null;
$alert-color: null;
$-zf-size: null;
$-zf-bp-value: null;

// Variables

$primary: #cb3c29 !default;
$primary-rgb: 239, 96, 77 !default;
$secondary: #39747f !default;
$secondary-rgb: 57, 116, 127 !default;
$success: #57d685 !default;
$success-rgb: 87, 214, 133 !default;
$warning: #ffae00 !default;
$warning-rgb: 255, 174, 0 !default;
$alert: #ec5840 !default;
$alert-rgb: 236, 88, 64 !default;
$highlight: #be6400 !default;
$highlight-rgb: 190, 100, 0 !default;
$highlight-alternative: #ff5731 !default;
$highlight-alternative-rgb: 255, 87, 49 !default;

$proposals: #238ff7 !default;
$actions: #57d685 !default;
$debates: #fa6c96 !default;
$meetings: #fabc6c !default;

$twitter: #000 !default;
$facebook: #3b5998 !default;
$google: #4285f4 !default;

// background colors used in public diff views
$color-addition: #e6ffed !default;
$color-removal: #ffeef0 !default;
